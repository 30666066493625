var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      !_vm.isDialog
        ? _c("div", { staticClass: "header padd15 x-f" }, [
            _c("h4", [_vm._v("素材中心")]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.mediaType,
                callback: function ($$v) {
                  _vm.mediaType = $$v
                },
                expression: "mediaType",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "图片", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "视频", name: "2" } }),
              _c("el-tab-pane", { attrs: { label: "素材", name: "3" } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "x-start marB10" },
            [
              _vm.mediaType == "1" ||
              _vm.$store.state.user.userinfo.tenantId == 2
                ? _c("c-button", {
                    staticClass: "whiteColor",
                    attrs: {
                      test: "上传图片",
                      icon: "el-icon-plus",
                      bgColor: "#1890ff",
                    },
                    on: { click: _vm.getUploadOpen },
                  })
                : _vm._e(),
              _vm.mediaType == "2"
                ? _c("c-button", {
                    staticClass: "whiteColor",
                    attrs: {
                      test: "上传视频",
                      icon: "el-icon-plus",
                      bgColor: "#1890ff",
                    },
                    on: { click: _vm.getUploadOpen },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("div", { staticClass: "x-start box" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.typeLoading,
                        expression: "typeLoading",
                      },
                    ],
                    staticClass: "lift-tree",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "treeBox padd15" },
                      [
                        _c("div", { staticClass: "x-bc marB10" }, [
                          _c("span", { staticClass: "fontS14" }, [
                            _vm._v("素材分组"),
                          ]),
                          _vm.mediaType == 1 ||
                          _vm.mediaType == 2 ||
                          _vm.$store.state.user.userinfo.tenantId == 2
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isEdit,
                                        expression: "isEdit",
                                      },
                                    ],
                                    staticClass: "fontS14B cursorP",
                                    on: { click: _vm.clickEdit },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isEdit,
                                        expression: "!isEdit",
                                      },
                                    ],
                                    staticClass: "fontS14B cursorP",
                                    on: { click: _vm.clickEdit },
                                  },
                                  [_vm._v("完成编辑")]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _c("el-input", {
                          staticClass: "marB10",
                          attrs: {
                            placeholder: "搜索分组名称",
                            "suffix-icon": "el-icon-search",
                          },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                        _c("el-tree", {
                          ref: "tree",
                          staticClass: "filter-tree",
                          attrs: {
                            data: _vm.groupTreeOptions,
                            props: _vm.defaultProps,
                            "default-expand-all": "",
                            "highlight-current": "",
                            "expand-on-click-node": false,
                            "filter-node-method": _vm.filterNode,
                          },
                          on: { "node-click": _vm.handleNodeClick },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "span",
                                  { staticClass: "material-tree-node" },
                                  [
                                    _c("span", [_vm._v(_vm._s(node.label))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isEdit,
                                            expression: "!isEdit",
                                          },
                                        ],
                                      },
                                      [
                                        node.label != "未分组"
                                          ? _c("el-button", {
                                              attrs: {
                                                icon: "el-icon-circle-plus-outline",
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.appendTree(data)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        node.label != "自定义分组" &&
                                        node.label != "未分组"
                                          ? _c("el-button", {
                                              attrs: {
                                                icon: "el-icon-edit",
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.editTree(
                                                    node,
                                                    data
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        node.label != "自定义分组" &&
                                        node.label != "未分组"
                                          ? _c("el-button", {
                                              attrs: {
                                                icon: "el-icon-delete",
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.removeTree(
                                                    node,
                                                    data
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "right-list padd15" }, [
                  _c("span", { staticClass: "boxTitle" }, [
                    _vm._v(_vm._s(_vm.groupingName)),
                  ]),
                  _c("div", { staticClass: "marT10 marB10 x-f" }, [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _vm.mediaType == 1 ||
                        _vm.mediaType == 2 ||
                        _vm.$store.state.user.userinfo.tenantId == 2 ||
                        _vm.isDialog
                          ? _c(
                              "div",
                              { staticClass: "x-x" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      indeterminate: _vm.isIndeterminate,
                                    },
                                    on: { change: _vm.handleCheckAllChange },
                                    model: {
                                      value: _vm.checkAll,
                                      callback: function ($$v) {
                                        _vm.checkAll = $$v
                                      },
                                      expression: "checkAll",
                                    },
                                  },
                                  [_vm._v("全选 ")]
                                ),
                                _c("span", { staticClass: "marR10 marL10" }, [
                                  _vm._v("|"),
                                ]),
                                _vm._v(
                                  " 已选 " + _vm._s(_vm.selectNum) + " 项 "
                                ),
                                _c("span", { staticClass: "marR10 marL10" }, [
                                  _vm._v("|"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.mediaType == 1 ||
                        _vm.mediaType == 2 ||
                        _vm.$store.state.user.userinfo.tenantId == 2
                          ? _c("c-button", {
                              staticClass: "whiteColor",
                              staticStyle: { height: "28px" },
                              attrs: {
                                test: "修改分组",
                                icon: "el-icon-setting",
                                border: "#9f9f9f",
                                color: "#6e6e6e",
                              },
                              on: { click: _vm.batchUpdateGroup },
                            })
                          : _vm._e(),
                        _vm.mediaType == 1 ||
                        _vm.mediaType == 2 ||
                        _vm.$store.state.user.userinfo.tenantId == 2
                          ? _c("c-button", {
                              staticClass: "whiteColor",
                              staticStyle: { height: "28px" },
                              attrs: {
                                test: "删除",
                                icon: "el-icon-delete",
                                border: "#9f9f9f",
                                color: "#6e6e6e",
                              },
                              on: { click: _vm.batchDeleteImg },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      staticClass: "x-w",
                    },
                    _vm._l(_vm.tableData, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "y-start list marL10 marB10",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "imgBox" },
                            [
                              _vm.mediaType == 1 || _vm.mediaType == 3
                                ? _c("el-image", {
                                    staticClass: "img",
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: {
                                      src: item.accessUrl,
                                      "preview-src-list": [item.accessUrl],
                                    },
                                  })
                                : _c("video", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                    },
                                    attrs: { src: item.accessUrl },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "x-f",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _vm.mediaType == 1 ||
                              _vm.mediaType == 2 ||
                              _vm.$store.state.user.userinfo.tenantId == 2 ||
                              _vm.isDialog
                                ? _c("el-checkbox", {
                                    staticClass: "checkItem",
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleCheckChange(item)
                                      },
                                    },
                                    model: {
                                      value: item.selectCheck,
                                      callback: function ($$v) {
                                        _vm.$set(item, "selectCheck", $$v)
                                      },
                                      expression: "item.selectCheck",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: item.fileName,
                                    placement: "top",
                                    effect: "light",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "marL10 omit",
                                      staticStyle: { width: "80px" },
                                    },
                                    [_vm._v(_vm._s(item.fileName))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.mediaType == 1 ||
                          _vm.mediaType == 2 ||
                          _vm.$store.state.user.userinfo.tenantId == 2
                            ? _c(
                                "div",
                                {
                                  staticClass: "x-ac",
                                  staticStyle: { width: "100%" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getEditImg(item)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c("span", { staticClass: "editBtn" }, [
                                    _vm._v("|"),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDeleteImg(item)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: _vm.groupTitle,
            visible: _vm.dialogGroupVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGroupVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.typeDiaLoading,
                  expression: "typeDiaLoading",
                },
              ],
              ref: "form",
              attrs: { model: _vm.groupForm, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所在分组",
                    "label-width": "80px",
                    prop: "parentId",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.groupTreeOptions,
                      "show-count": true,
                      placeholder: "请选择所在分组",
                      normalizer: _vm.normalizer,
                    },
                    on: { input: _vm.inputSelect },
                    model: {
                      value: _vm.groupForm.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.groupForm, "parentId", $$v)
                      },
                      expression: "groupForm.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分组名称",
                    "label-width": "80px",
                    prop: "typeName",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "inlineBlock",
                    attrs: { placeholder: "20个字以内", autocomplete: "off" },
                    model: {
                      value: _vm.groupForm.typeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.groupForm, "typeName", $$v)
                      },
                      expression: "groupForm.typeName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.groupSubmit } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.groupCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            "destroy-on-close": "",
            title: "上传图片",
            visible: _vm.uploadImgVisible,
            width: "40%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadImgVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.uploadDiaLoading,
                  expression: "uploadDiaLoading",
                },
              ],
              ref: "uploadForm",
              attrs: { model: _vm.uploadForm, rules: _vm.uploadRules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所在分组",
                    "label-width": "80px",
                    prop: "materialTypeId",
                  },
                },
                [
                  _c("treeselect", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      options: _vm.groupTreeOptions,
                      "show-count": true,
                      placeholder: "请选择所在分组",
                      normalizer: _vm.normalizer,
                    },
                    on: { input: _vm.inputSelectUpload },
                    model: {
                      value: _vm.uploadForm.materialTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.uploadForm, "materialTypeId", $$v)
                      },
                      expression: "uploadForm.materialTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "* 本地图片", "label-width": "80px" } },
                [
                  _c("accessory-upload", {
                    attrs: {
                      listType: "picture-card",
                      isLimitMany: true,
                      limit: 100000000,
                      title: "",
                    },
                    on: {
                      getFileItems: _vm.getFileItemsData,
                      delFileItems: _vm.delFileItems,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.uploadSubmit } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.uploadCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            "destroy-on-close": "",
            title:
              _vm.mediaType == 1 || _vm.mediaType == 3
                ? "上传图片"
                : "上传视频",
            visible: _vm.uploadVideoVisible,
            width: "40%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVideoVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.uploadDiaLoading,
                  expression: "uploadDiaLoading",
                },
              ],
              ref: "uploadForm",
              attrs: { model: _vm.uploadForm, rules: _vm.uploadRules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所在分组",
                    "label-width": "80px",
                    prop: "materialTypeId",
                  },
                },
                [
                  _c("treeselect", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      options: _vm.groupTreeOptions,
                      "show-count": true,
                      placeholder: "请选择所在分组",
                      normalizer: _vm.normalizer,
                    },
                    on: { input: _vm.inputSelectUpload },
                    model: {
                      value: _vm.uploadForm.materialTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.uploadForm, "materialTypeId", $$v)
                      },
                      expression: "uploadForm.materialTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "* 本地视频", "label-width": "80px" } },
                [
                  _c("upload-video", {
                    on: { getFileItems: _vm.getFileItemsData },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.uploadSubmit } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.uploadCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title:
              _vm.mediaType == 1 || _vm.mediaType == 3
                ? "编辑图片"
                : "编辑视频",
            visible: _vm.dialogEditImgVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditImgVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editImgDiaLoading,
                  expression: "editImgDiaLoading",
                },
              ],
              ref: "editImgForm",
              attrs: { model: _vm.editImgForm, rules: _vm.editImgRules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所在分组",
                    "label-width": "80px",
                    prop: "materialTypeId",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.groupTreeOptions,
                      "show-count": true,
                      placeholder: "请选择所在分组",
                      normalizer: _vm.normalizer,
                    },
                    model: {
                      value: _vm.editImgForm.materialTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.editImgForm, "materialTypeId", $$v)
                      },
                      expression: "editImgForm.materialTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.mediaType == 1 || _vm.mediaType == 3
                        ? "图片标题"
                        : "视频标题",
                    "label-width": "80px",
                    prop: "fileName",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "inlineBlock",
                    attrs: { placeholder: "50个字以内", autocomplete: "off" },
                    model: {
                      value: _vm.editImgForm.fileName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editImgForm, "fileName", $$v)
                      },
                      expression: "editImgForm.fileName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editImgSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.editImgCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: "修改分组",
            visible: _vm.batchUpdateVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchUpdateVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.batchUpdateDiaLoading,
                  expression: "batchUpdateDiaLoading",
                },
              ],
              ref: "batchUpdateForm",
              attrs: {
                model: _vm.batchUpdateForm,
                rules: _vm.batchUpdateFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所在分组",
                    "label-width": "80px",
                    prop: "materialTypeId",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.groupTreeOptions,
                      "show-count": true,
                      placeholder: "请选择所在分组",
                      normalizer: _vm.normalizer,
                    },
                    model: {
                      value: _vm.batchUpdateForm.materialTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.batchUpdateForm, "materialTypeId", $$v)
                      },
                      expression: "batchUpdateForm.materialTypeId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.batchUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.batchUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }